import Immutable from 'immutable'
import Cookies from 'cookies-js'

const cookie = Cookies.get('TEMPUS_DATA')
const data = cookie ? JSON.parse(atob(cookie)) : {}

export let USERNAME = data.username ?? 'anonymous'
export let PLAYERNAME = data.playername ?? ''
export let STEAMID = data.steamid ?? ''
export let PLAYERID = data.playerid ?? null
export let PERMISSIONS = Immutable.List(data.permissions ?? ['public'])
export let TEMPUS_PERMISSIONS = Immutable.List(data.tempus_permissions ?? [])
export let REALM = data.realm ?? 'jump'
export let DEPLOYMENT = data.deployment ?? 'jump'
export let DEPLOYMENT_TYPE = data.deployment_type ?? 'jump'
export let WEBSOCKET_PORT = parseInt(data.websocket_port ?? 9999)

const pollCookie = (() => {
    const lastCookie = Cookies.get('TEMPUS_DATA')

    return () => {
        const currentCookie = Cookies.get('TEMPUS_DATA')
        if (currentCookie != lastCookie) {
            window.location.reload()
            lastCookie = currentCookie
        }
    }
})()

window.setInterval(pollCookie, 10000)