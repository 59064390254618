import React from 'react'

import './styles.styl'


const AuthorOverview = () => {
    return <div className="AuthorOverview container">Not yet implemented</div>
}


export default AuthorOverview
